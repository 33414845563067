<template lang="pug">
h1 Recuperar cuenta
p Introduce tu dirección de correo electrónico, te reenviaremos la contraseña.
.form.flex.flex-col.gap-4.mt-4
  BaseInputText(label="Correo electronico")
  .remember-psw 
    .link(@click="$emit('setView', 'FormLogin')") Volver a Login
  .form-button Recuperar
</template>

<script>
export default {
  name: "card-remember-login",
  emits: ["setView"],
};
</script>

<style lang="scss" scoped>
h1 {
  @apply text-3xl font-bold;
}
p {
  @apply text-sm my-2;
}
.form {
  .remember-psw {
    @apply flex justify-end;

    .link {
      @apply text-sm text-right cursor-pointer text-gray-400 transition-colors inline-block;
      &:hover {
        @apply underline text-gray-600;
      }
    }
  }

  .form-button {
    @apply bg-blue-600 text-center text-white py-3 rounded-lg text-lg cursor-pointer;
  }
}
</style>
