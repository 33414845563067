<template lang="pug">
baseInputSelect(
  :listOptions="languages_template",
  v-model="selectedLanguage",
  position="top"
)
</template>

<script>
import { loadLocaleMessages } from "@/languages";
export default {
  name: "selectorLanguage",
  data() {
    return {
      selectedLanguage: {
        text: "English",
        id: "en",
      },
      languages_template: [
        {
          text: "English",
          id: "en",
        },
        {
          text: "Deutsch",
          id: "de",
        },
        {
          text: "Italiano",
          id: "it",
        },
        {
          text: "Français",
          id: "fr",
        },
        {
          text: "Español",
          id: "es",
        },
        {
          text: "Português",
          id: "pt",
        },
        {
          text: "Nederlands",
          id: "nl",
        },
        {
          text: "Polski",
          id: "pl",
        },
      ],
    };
  },
  created() {
    this.selectedLanguage = this.languages_template.find(
      (l) => l.id == (localStorage.getItem("cv-locale") || "en")
    );
  },
  watch: {
    selectedLanguage(n) {
      localStorage.setItem("cv-locale", n.id);
      loadLocaleMessages(n.id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
