<template lang="pug">
XyzTransition(appear, xyz="up-1 fade")
  nav.py-3.w-full
    .container.flex.items-center.justify-between
      baseLogoWeb
      buttonNavLeft(:links="listLinks")
      navInline.box-inline-links(:links="listLinks")
ModalLoginComponent(
  :showModal="showModalLogin",
  v-on:closeModal="showModalLogin = false"
)
</template>
<script>
import buttonNavLeft from '@/components/structure/navLeft/buttonNavLeft.vue'
import navInline from "./nav-inline.vue";
import ModalLoginComponent from "@/components/www/login/modal-login.vue";

export default {
  name: "navbar",
  components: {
    buttonNavLeft,
    navInline,
    ModalLoginComponent,
  },
  provide() {
    return {
      setShowModalLogin: (show) => (this.showModalLogin = show),
      logout: () => this.$store.dispatch("UserStore/logout"),
    };
  },
  data: () => ({
    showNavMenu: false,
    showModalLogin: false,
    linksDefault: [
      {
        routeLink: "builder/select-template",
        routeName: "Crear mi CV",
      },
      {
        routeLink: "builder/select-template",
        routeName: "Plantillas",
      },
      {
        routeLink: "--",
        routeName: "login",
      },
    ],
    linksLoggedIn: [
      {
        routeLink: "my-cvs",
        routeName: "Mis CV's",
      },
      {
        routeLink: "my-account",
        routeName: "Mi cuenta",
      },
      {
        routeLink: "--",
        routeName: "logout",
      },
    ],
  }),
  computed: {
    listLinks() {
      const islogged = this.$store.getters["UserStore/getIsLogged"];
      if (islogged) return this.linksLoggedIn;
      else return this.linksDefault;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-inline-links {
  @apply hidden sm:flex;
}

.box-inline-icon {
  @apply rounded-full p-1 cursor-pointer text-blue-400 sm:hidden;
  &:hover {
    @apply bg-blue-100;
  }
}
</style>