<template lang="pug">
navBarLink(v-if="hasDownloads")
  .button-downloads 
    .total {{ totalDownloads }}
      .text Descargas
</template>
<script>
import navBarLink from "./navbar-link.vue";

export default {
  name: "link-downloads",
  components: {
    navBarLink,
  },
  computed: {
    hasDownloads() {
      return this.$store.getters["UserAccountDataStore/hasDownloads"];
    },
    totalDownloads() {
      return this.$store.getters["UserAccountDataStore/getDownloads"];
    },
  },
};
</script>

<style lang="scss" scoped>
.button-downloads {
  @apply flex items-center cursor-default;

  .text {
    @apply pl-2;
  }

  .total {
    @apply bg-blue-400 py-px px-4 flex items-center justify-center text-white font-medium rounded-full mr-2;
  }
}
</style>
