<template lang="pug">
.footer
  .container.pb-0
    .footer-wrapper
      .col-1
        .box-logo
          baseLogoWeb
        .box-languages
          selectorLanguage
      .col-2
        .box-links
          .text-xl.font-bold.text-yellow-400 Cuenta
          ul.text-sm
            li Crear Curriculum
            li Mis Curriculums
            li 
              router-link(:to="{ name: 'Plantillas' }") Plantillas
            li Mi Cuenta
        .box-links
          .text-xl.font-bold.text-green-500 Paginas
          ul.text-sm
            li
              router-link(:to="{ name: 'Faq' }") FAQ
            li 
              router-link(:to="{ name: 'Contacto' }") Contacto
            li.cancel
              router-link(:to="{ name: 'Cancelar' }") {{ $t('unsubscribe.footer_link') }}
            li 
              router-link(:to="{ name: 'Precios' }") {{ $t('common.price') }}
        .box-links
          .text-xl.font-bold.text-blue-500 Información
          ul.text-sm
            li
              router-link(:to="{ name: 'Cookies' }") Cookies
            li 
              router-link(:to="{ name: 'Privacidad' }") Privacidad
            li 
              router-link(:to="{ name: 'Terminos' }") Condiciones
    .w-full.h-px.bg-gray-200.mt-16
    .text-sm.text-right.py-2.text-gray-400 © bestcv.app - {{ new Date().getFullYear() }}

.w-full.h-2.bg-green-400
</template>
<script>
import selectorLanguage from "@/components/molecules/selectorLanguage";
export default {
  name: "navFooter",
  components: {
    selectorLanguage,
  },
};
</script>
<style lang="scss" scoped>
ul li {
  @apply mt-2;
  @apply text-gray-500;
  a:hover {
    @apply text-blue-700;
    @apply transition;
    @apply transition-colors;
  }

  &.cancel a:hover {
    @apply text-red-500;
  }
}

.footer {
  @apply w-full bg-gray-50 pt-8 border-t border-gray-100 mt-auto md:pt-20;
}
.footer-wrapper {
  @apply grid grid-cols-1 gap-5 md:grid-cols-5;
}

.col-1 {
  @apply flex flex-col items-center md:col-span-2 md:items-start;

  .box-logo {
    @apply mb-6 self-start md:mb-auto;
  }
  .box-languages {
    @apply w-full md:max-w-xs;
  }
}

.col-2 {
  @apply flex flex-col gap-6 pt-8 sm:flex-row sm:justify-between md:col-span-3 md:pt-0;
  .box-links {
    @apply flex flex-col flex-grow;
  }
}
</style>