<template lang="pug">
h1 Iniciar sesión
p Introduce tu dirección de correo electrónico y tu contraseña para iniciar sesión.
.form.flex.flex-col.gap-4.mt-4
  BaseInputText(
    v-model="user_email",
    label="Correo electronico",
    :error="error_email",
    :disabled="isLoading"
  )
  BaseInputText(
    v-model="user_password",
    label="Contraseña",
    :error="error_password",
    inputType="password",
    :disabled="isLoading"
  )
  .remember-psw 
    .link(@click="$emit('setView', 'FormRememberPsw')") ¿Has olvidado la contraseña?
  .form-button(@click="intentLogin") Iniciar sesión
  .error(v-if="!!error_unauthorized") {{ error_unauthorized }}
baseGlobalLoader(:show="isLoading")
</template>

<script>
import { UsersRepository } from "@/repository/users.repository";
import emailValidator from "@/composables/validator-email";
import sleep from "@/composables/sleep";

export default {
  name: "card-login",
  emits: ["setView"],
  inject: ["setShowModalLogin"],
  data: () => ({
    isLoading: false,
    user_email: "",
    user_password: "",
    error_email: "",
    error_password: "",
    error_unauthorized: "",
  }),
  watch: {
    user_email() {
      this.error_email = "";
      this.error_unauthorized = "";
    },
    user_password() {
      this.error_password = "";
      this.error_unauthorized = "";
    },
  },
  methods: {
    async intentLogin() {
      this.isLoading = true;
      this.error_email = "";
      this.error_password = "";
      this.error_unauthorized = "";
      const { isValidEmail } = emailValidator();
      const validEmail = isValidEmail(this.user_email);
      if (!validEmail) {
        this.error_email = "El correo no es valido.";
      }
      if (this.user_password.length < 3) {
        this.error_password = "LA contraseña es incorrecta.";
      }
      if (validEmail && this.user_password.length >= 3) await this.fetchLogin();
      this.isLoading = false;
    },

    async fetchLogin() {
      UsersRepository.login({
        user_email: this.user_email,
        password: this.user_password,
      })
        .then(async (response) => {
          this.setShowModalLogin(false);
          const { timer } = sleep();
          await timer(250);
          this.$store.dispatch("UserStore/autoLogin", {
            access_token: response.access_token,
            forceRedirect: true,
          });
        })
        .catch((e) => {
          this.error_unauthorized =
            "Correo electronico o contraseña incorrectos.";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  @apply text-3xl font-bold;
}
p {
  @apply text-sm my-2;
}
.form {
  .remember-psw {
    @apply flex justify-end;

    .link {
      @apply text-sm text-right cursor-pointer text-gray-400 transition-colors inline-block;
      &:hover {
        @apply underline text-gray-600;
      }
    }
  }

  .form-button {
    @apply bg-blue-600 text-center text-white py-3 rounded-lg text-lg cursor-pointer;
  }
}

.error {
  @apply text-xs -mb-7 -mt-1 text-red-600;
}
</style>
